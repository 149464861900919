import React from 'react';
import PropTypes from 'prop-types';

export const Page = ({
  className = 'sm:px-16',
  title,
  description,
  children,
}) => {
  return (
    <section className={className}>
      <h1 className="text-center pb-8 text-4xl" data-testid="page-title">
        {title}
      </h1>
      {description && (
        <p
          className="text-center pb-8 text-lg max-w-3xl mx-auto"
          dangerouslySetInnerHTML={{ __html: description }}
          data-testid="page-description"
        />
      )}
      {children}
    </section>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.any.isRequired,
};
