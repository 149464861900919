import React from 'react';

import { Layout } from '../components/shared/Layout';
import { Page } from '../components/shared/Page';
import { Button } from '../components/shared/Button';

const Test01 = () => {
  const zonkyOnbURL =
    'https://app-test02.zonkej.cz/poptavka?utm_source=osobniskore.cz/&utm_medium=link&utm_campaign=result&interestRate=0.0699';

  return (
    <Layout theme="in-app">
      <Page
        className="md:w-9/12 lg:w-8/12 xl:w-1/2 mx-auto space-y-8 text-center"
        title="Proklik na zonky-app onboarding"
      >
        <Button
          type="button"
          href={zonkyOnbURL}
          className="mx-auto my-6 px-16"
          border="normal"
        >
          Zonky onboarding
        </Button>
      </Page>
    </Layout>
  );
};

export default Test01;
